import React from "react"
import { graphql } from "gatsby"
import 'ssr-intersection-observer'
import Consumer from "../layouts/Context"

// Components
import SEO from "../components/seo"
import ArticleBottom from "../components/ArticleBottom/ArticleBottom"

class Accessible extends React.Component {
  componentDidMount() {
    this.context.set({
      accessibility: true
    })
  }

  render() {
    require("../templates/css/accessibility-article.scss")

    const post = this.props.data.markdownRemark,
          seoDescription = post.frontmatter.seoDescription,
          seoImage = post.frontmatter.seoImage,
          title = post.frontmatter.title,
          subtitle = post.frontmatter.subtitle,
          author = post.frontmatter.author,
          htmlContent = post.html,
          youtubeCode = post.frontmatter.youtubeCode,
          youtubeUrl = `https://www.youtube.com/embed/${youtubeCode}`,
          galleryItems = post.frontmatter.galleryItems,
          gallery = post.frontmatter.gallery

    return (
      <>
        <SEO
          title={title}
          description={seoDescription ? seoDescription : post.excerpt}
          image={seoImage}
        />
        <div className="article" data-style="accessibility">
          <div className="article__inner">
            <div className="container">

              <div className="cols">
                <div className="col m12">
                  <div className="article__container">
                    <h4 dangerouslySetInnerHTML={{ __html: author }} />
                    <h1 className="h2" dangerouslySetInnerHTML={{ __html: title }} />
                    <h3 dangerouslySetInnerHTML={{ __html: subtitle }} />
                    {youtubeCode &&
                      <>
                        <div className="article__video">
                          <iframe
                            width="1240"
                            height="900"
                            src={youtubeUrl}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title={title}
                          />
                        </div>
                      </>
                    }
                    {galleryItems &&
                      <div className="article__image-stack">
                        <ul>
                          {Object.keys(galleryItems).map((index) => {
                            const url = galleryItems[index].publicURL

                            return (
                              <li key={index}>
                                <img src={url} width="668" height="849" title={title} alt={title} />
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    }
                    {gallery &&
                      <div className="article__image-stack">
                        <ul>
                          {Object.keys(gallery).map((index) => {
                            const src = gallery[index].url.publicURL,
                                  author = gallery[index].author

                            return (
                              <li key={index}>
                                <img src={src} width="1360" height="760" alt={author} />
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                    }
                    <div
                      className="article__content"
                      dangerouslySetInnerHTML={{ __html: htmlContent }}
                    />
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="article__bottom">
            <ArticleBottom access="true" />
          </div>

        </div>
      </>
    )
  }
}

Accessible.contextType = Consumer

export default Accessible

export const pageQuery = graphql`
  query AccessibleBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        seoDescription
        seoImage
        chapter
        title
        subtitle
        author
        authorAvatar {
          publicURL
        }
        authorBio
        youtubeCode
        gallery {
          url {
            publicURL
          }
          author
        }
        galleryItems {
          publicURL
        }
      }
    }
  }
`