import React from "react"
import Consumer from "../../layouts/Context"
import { TweenMax, Power3 } from "gsap/TweenMax"
import TransitionLink from 'gatsby-plugin-transition-link'

// Components
import ArrowBlue from "../ArrowBtn/images/arrow-story-bottom.png"

class ArticleBottom extends React.Component {

  exitArticle = (node, e, exit, entry) => {
    this.context.set({
      homeInit: true
    })

    TweenMax.to(this.props.pageWipe, .75, {ease: Power3.easeOut, x: "0"})
  }

  render() {
    const {access} = this.props

    return (
      <div className="container">
        <h2>Back to Finding ctrl</h2>
        {
          access && (
            <TransitionLink
              to="/text"
              aria-label="Back"
              className="arrow arrow--blue"
              title="Back"
              exit={{
                length: .2,
                zIndex: 2
              }}
              entry={{
                length: .2,
                delay: .2,
                zIndex: 1
              }}
            >
              <img src={ArrowBlue} title="Back" width="165" height="105" alt="Back" />
            </TransitionLink>
          )
        }
        {
          !access && (
            <TransitionLink
              to="/"
              aria-label="Back"
              className="arrow arrow--blue"
              title="Back"
              exit={{
                trigger: ({node, e, exit, entry}) => this.exitArticle(node, e, exit, entry),
                length: .75,
                zIndex: 2
              }}
              entry={{
                length: .75,
                delay: .75,
                zIndex: 1
              }}
            >
              <img src={ArrowBlue} title="Back" width="165" height="105" alt="Back" />
            </TransitionLink>
          )
        }
      </div>
    )
  }
}

ArticleBottom.contextType = Consumer

export default ArticleBottom